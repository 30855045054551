/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


// font-family: 'Poppins', sans-serif;
.custom-card {
    padding: 10px 10px;
    margin: 10px 15px;

    background: #FFFFFF;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
}

@font-face {
    font-family: 'regular';
    src: url(assets/fonts/Poppins-Regular.ttf);
}

@font-face {
    font-family: 'medium';
    src: url(assets/fonts/Poppins-Medium.ttf);
}

@font-face {
    font-family: 'semi-bold';
    src: url(assets/fonts/Poppins-SemiBold.ttf);
}

@font-face {
    font-family: 'bold';
    src: url(assets/fonts/Poppins-Bold.ttf);
}

* {
    font-family: 'regular';
}

ion-label {
    display: block;
}


ion-modal.custom_modal {
  .modal-wrapper {
    width: 100% !important;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }
  &.bottom {
    align-items: flex-end !important;
  }
  --height: 400px;
  align-items: flex-end;
}
ion-footer {
  ion-toolbar {
    ion-title {
      font-family: "Poppins", sans-serif;
    }
  }
}
ion-button {
  --background-activated: none;
  --background-focused: none;
  --background-hover: none;
  --background-activated-opacity: 1;
  --background-focused-opacity: 1;
  --background-hover-opacity: 1;
}




:root {
  --white: #fff;
  --primary: #3859a1;
  --transparent: rgba(0, 0, 0, 0);
  --text-light: #A0A0A0;
  --text-dark: #3b3b3b;
  --text-black: #0e0e0f;
  --bg-color: #f1f1f1;
}

.bg_color {
  --background: var(--bg-color);
}

.d-flex {
  display: flex !important;
  align-items: center;
  width: 100%;
  .end {
      margin-left: auto !important;
      margin-right: 0 !important;
      display: block;
  }
}

.center_img {
  position: relative;
  overflow: hidden;
  img {
      &.crop_img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          bottom: 0;
          left: -50% !important;
          right: -50% !important;
          margin: auto;
      }
  }
}

.button {
  &.btn {
      width: 100%;
      font-size: 0.93rem;
      font-weight: 500;
      height: 50px;
      --background: var(--primary);
      color: var(--white);
      margin: 0;
      --box-shadow: none;
      padding: 0;
      text-transform: none;
      letter-spacing: 0.6px;
      ion-icon {
          font-size: 1.4rem;
      }
      &:hover:not(.disable-hover) {
          --background: var(--primary);
      }
      &.button-outline {
          --background: var(--white);
          --border-color: var(--primary);
          --border-width: 1px;
          font-size: 0.98rem;
          font-weight: 500;
          letter-spacing: .5px !important;
          color: var(--primary);
          &:hover:not(.disable-hover) {
              --background: var(--transparent);
          }
      }
      &.button-round {
          --border-radius: 50px !important;
      }
      &.button-block {
          --border-radius: 6px !important;
      }
  }
}

ion-header {
  z-index: 999;
  &::after {
      display: none !important;
  }
  ion-toolbar {
      --background: var(--white);
      display: flex;
      align-items: center;
      min-height: 60px !important;
      height: auto;
      overflow: visible !important;
      --border-width: 0 !important;
      ion-buttons {
          position: relative !important;
          margin: 0;
          --padding-end: 0 !important;
          --padding-start: 0 !important;
          ion-menu-button,
          ion-back-button {
              //				--color: var(--white) !important;
              --color: var(--text-black) !important;
              --icon-font-size: 1.3rem !important;
              --padding-end: 0 !important;
              --padding-start: 0 !important;
          }
          &.ios {
              padding: 0 10px !important;
          }
      }
      ion-title {
          color: var(--text-black);
          padding: 0px !important;
          padding-right: 15px !important;
          text-align: justify;
          font-size: 1.1rem;
          font-weight: 500;
          letter-spacing: 0;
          position: relative !important;
          overflow: visible !important;
          line-height: 60px;
          ion-icon {
              font-size: 1.3rem !important;
          }
      }
  }
  &.bg_transparent {
      ion-toolbar {
          --background: var(--transparent);
          --ion-color-base: transparent !important;
      }
  }
}

.form {
  display: block;
  position: relative;
  width: calc(100% - 40px);
  margin: 0 auto;
  ion-list {
      background: var(--transparent) !important;
      margin: 0;
      padding: 0;
  }
  ion-item {
      --min-height: 50px !important;
      --background: var(--transparent);
      --inner-padding-end: 0px;
      --inner-min-height: unset !important;
      --padding-start: 0;
      --highligh-color-focused: var(--transparent) !important;
      box-shadow: none !important;
      padding: 0px 0 0px 0;
      --ripple-color: var(--transparent) !important;
      --background-focused: var(--transparent) !important;
      border-bottom: 1px solid #e4e4e4 !important;
      margin: 23px 0;
      .icon_box {
          margin: 0 !important;
          margin-right: 13px !important;
          position: relative;
          top: 2px;
          img {
              width: 20px;
          }
      }
      ion-label {
          color: var(--text-light) !important;
          font-weight: 500 !important;
          font-size: 0.95rem !important;
          letter-spacing: 0.5px;
      }
      ion-textarea,
      ion-input {
          --padding: 0 !important;
          font-weight: 500 !important;
          font-size: 1.1rem !important;
          --padding-start: 0 !important;
          --padding-end: 0 !important;
          --padding-bottom: 0px !important;
          color: var(--text-black);
          --placeholder-color: var(--text-light) !important;
          --placeholder-opacity: 1;
          --placeholder-font-weight: 400 !important;
          flex: unset !important;
          letter-spacing: 1px;
          --padding-top: 5px !important;
          input {
              --padding: var(--no-padding);
              &.native-input {
                  --padding: var(--no-padding);
              }
          }
      }
      &.item-select {
          ion-select {
              padding: 0;
              font-weight: 500 !important;
              font-size: 1.1rem !important;
              color: var(--text-black);
              letter-spacing: 1px;
              min-height: 35px;
          }
      }
  }
  ion-row {
      margin: 0 -5px;
  }
}

.alert-wrapper {
  border-radius: 5px !important;
  min-width: calc(100% - 58px) !important;
  max-height: 484px !important;
  height: auto;
  .alert-head {
      background: var(--bg-color);
      padding: 15px 20px 15px 20px;
      h2 {
          text-transform: none;
          font-size: 1rem;
      }
  }
  .alert-radio-group {
      max-height: 291px !important;
      .alert-radio-button {
          .alert-button-inner {
              .alert-radio-label {
                  font-size: 1.2rem;
                  color: var(--text-black);
                  font-weight: 500;
              }
          }
      }
  }
  .alert-button-group {
      padding: 0;
      display: flex;
      align-items: center;
      position: relative;
      &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          width: 1.5px;
          height: 100%;
          background-color: var(--white);
          z-index: 9999;
          margin: 0 auto;
      }
      .alert-button {
          color: var(--white);
          background: var(--primary);
          height: 45px;
          text-align: center;
          margin: 0;
          width: 50%;
          .alert-button-inner {
              width: 100%;
              display: block;
              height: fit-content;
              font-size: .78rem;
              text-transform: none;
              letter-spacing: 0.4px;
          }
      }
  }
}

ion-modal {
  --background: rgba(0, 0, 1, 0.267) !important;
}

ion-modal {
  &.my-custom-class {
      --width: 66%;
      justify-content: flex-end;
      --backdrop-opacity: .1 !important;
  }
}

ion-app[dir="rtl"] {
  .d-flex .end {
      margin-left: 0 !important;
      margin-right: auto !important;
  }
  ion-header ion-toolbar {
      ion-title {
          padding-right: 0px !important;
          padding-left: 15px !important;
      }
      ion-buttons ion-back-button {
          transform: rotate(-180deg);
      }
  }
  app-sign-in {
      .socile_media ion-row ion-col .button.btn img {
          left: unset !important;
          right: -12px !important;
      }
  }
  app-profile {
      .img_container .profile-img ion-icon.add-pic {
          right: unset !important;
          left: 7px !important;
      }
  }
  app-home {
      .tab_content ion-list ion-item .item_inner {
          .item_header .profile_details .profile_img {
              margin-right: 0px !important;
              margin-left: 10px !important;
          }
      }
      ion-footer {
          right: unset !important;
          left: -100%;
          .options {
              right: unset !important;
              left: 0 !important;
          }
          &.active {
              right: unset !important;
              left: 0 !important;
              .options {
                  left: unset !important;
                  right: 0 !important;
              }
          }
      }
  }
  app-text-post,
  app-create-post {
      .form ion-list ion-item .item_inner .profile_img {
          margin-right: 0 !important;
          margin-left: 15px !important;
      }
  }
  app-comments {
      ion-footer {
          .user_details ion-row ion-col .profile_img {
              margin-right: 0px !important;
              margin-left: 10px !important;
          }
          ion-list ion-item .item_inner .img_box {
              margin-right: 0px !important;
              margin-left: 10px !important;
          }
          .form ion-item .item_inner .profile_img {
              margin-right: 0px !important;
              margin-left: 10px !important;
          }
      }
  }
  app-add-story {
      ion-footer p {
          left: unset !important;
          right: -12px !important;
      }
  }
  app-fullview {
      ion-header ion-toolbar ion-title .user_setails .profile_img {
          margin-right: 0 !important;
          margin-left: 15px !important;
      }
      ion-footer h2 {
          left: unset !important;
          right: -12px !important;
      }
  }
  app-notification {
      .tab_content ion-list ion-item {
          padding: 7px 13px 7px 7px !important;
          .item_inner .profile_img {
              margin-right: 0px !important;
              margin-left: 10px !important;
          }
      }
  }
  app-chats {
      .tab_content ion-list ion-item .item_inner {
          .img_box {
              margin-right: 0px !important;
              margin-left: 10px !important;
              ion-badge {
                  right: unset !important;
                  left: 0 !important;
              }
          }
      }
  }
  app-group-chat,
  app-chat-screen {
      ion-header ion-toolbar ion-title .user_profile .profile_img {
          margin-right: 0px !important;
          margin-left: 10px !important;
      }
      .chat_container .chat_box {
          &.send {
              margin-left: 0 !important;
              margin-right: auto !important;
              .chat {
                  padding: 14px 30px 17px 12px !important;
                  h3,
                  h2 {
                      text-align: left !important;
                  }
              }
          }
          &.received {
              .chat {
                  padding: 14px 12px 17px 30px !important;
              }
          }
      }
  }
  app-group-chat {
      .chat_container .chat_box {
          &.send {
              .chat {
                  padding: 9px 30px 9px 12px !important;
              }
          }
          &.received {
              .chat {
                  padding: 9px 12px 9px 30px !important;
              }
          }
      }
  }
  app-create-group {
      ion-list ion-item .item_inner .profile_img {
          margin-right: 0 !important;
          margin-left: 15px !important;
      }
      ion-footer .scroll_container .profile_img_box {
          &:first-child {
              margin-left: 5px !important;
              margin-right: 13px !important;
          }
          &:last-child {
              margin-right: 5px !important;
              margin-left: 13px !important;
          }
          ion-icon {
              right: unset !important;
              left: -2px !important;
              &::after {
                  right: unset !important;
              }
          }
      }
  }
  app-group-info {
      .members_in_group ion-list ion-item .item_inner .profile_img {
          margin-right: 0 !important;
          margin-left: 15px !important;
      }
  }
  app-user-profile,
  app-my-profile {
      .tab_content ion-list ion-item .item_inner .item_header .profile_details .profile_img {
          margin-right: 0px !important;
          margin-left: 10px !important;
      }
  }
}
